import React from "react";
import Earn from "./components/Earn/Earn";
import Featured from "./components/Featured/Featured";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Navbar from './components/Navbar/Navbar'

function App() {
  return (
    <div>
      <Navbar/>
      <Hero/>
      <Featured/>
      <Earn/>
      <Footer/>
    </div>
  );
}

export default App;
