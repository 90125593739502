import React from 'react'
import './Earn.css'
import MobilePic from '../../assets/trade.png'

const Earn = () => {
  return (
    <div className="earn">
        <div className="container">
            <div className="left">
                <img src={MobilePic} alt="" />
            </div>

            <div className="right">
                <div className="income">
                    <h2>Earn passive income with<br/>crypto.</h2>
                    <p>Earn up to 12% annual rewards on 30+ digital assets. Simple<br/>
                    hold your assets in the app to automatically earn rewards at<br/>
                    the end of each month with no lockups and no limits.</p>
                    <input type="text" placeholder='Enter your email'/>
                    <button className='btn'>Learn More</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Earn
